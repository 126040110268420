import React, { forwardRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Moment from "react-moment"
import _ from "lodash"

// @react-datepicker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Pager from "../components/elements/pager-buttons"
import Breadcrumb from "../components/elements/breadcrumb"
import ContentToHTML from "../components/elements/content-html"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetEvents } from "../hooks/use-asmet-events"

const iconCalendar = require("../assets/images/icons/tools/calendar-event.png")

const EventsPage = ({ data, pageContext }) => {
  const allEventos = useAsmetEvents()
  const queriedEventos = data.allStrapiMediaEvento.edges

  const asmetHome = useAsmetHome()
  const [filteredEvents, setFilteredEvents] = useState(queriedEventos)

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const ref = React.createRef() //https://github.com/Hacker0x01/react-datepicker/issues/862

  useEffect(() => {
    startDate == null && endDate == null
      ? setFilteredEvents(queriedEventos)
      : setFilteredEvents(
          _.filter(allEventos, function (event) {
            return _.inRange(
              Date.parse(event.node.fecha),
              Date.parse(startDate ? startDate : new Date(1900, 0, 1)),
              Date.parse(endDate ? endDate : new Date(2999, 0, 1))
            )
          })
        )
  }, [startDate, endDate, queriedEventos, allEventos])

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/events`, label: "Eventos" },
  ]

  const CustomDateInput = forwardRef(({ onClick, value }, ref) => (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text bg-blue-alpha border-0 px-4">
          <i className="fa fa-calendar text-white-alpha"></i>
        </span>
      </div>
      <input
        type="text"
        className="form-control"
        value={value}
        onClick={onClick}
        onChange={onClick}
        ref={ref}
      />
    </div>
  ))

  return (
    <Layout meta={{ og_titulo: "Eventos" }}>
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-center pt-4">
                Calendario de Eventos
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div id="asm-events-filter" className="container my-5 position-relative">
        <img
          src={iconCalendar}
          alt="Asmet Eventos - Calendario"
          className="events-filter-icon position-absolute"
        />
        <form>
          <div className="form-row bg-black-alpha justify-content-center rounded py-4">
            <div className="col-6 col-sm-5 px-2 px-sm-5">
              <label htmlFor="eventsFrom" className="text-blue-alpha mb-0">
                <h5>Desde</h5>
              </label>
              <DatePicker
                selected={startDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                isClearable
                closeOnScroll={true}
                customInput={<CustomDateInput ref={ref} />}
              />
            </div>
            <div className="col-6 col-sm-5 px-2 px-sm-5">
              <label htmlFor="eventsTo" className="text-blue-alpha mb-0">
                <h5>Hasta</h5>
              </label>
              <DatePicker
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable
                closeOnScroll={true}
                customInput={<CustomDateInput ref={ref} />}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="asm-media-main container my-5">
        <div id="asm-media-list-cards" className="row row-cols-1">
          {filteredEvents.map((evento, index) => {
            return (
              <div className="col mb-4" key={index}>
                <div className="card rounded-0 border-0">
                  <div className="row no-gutters position-relative">
                    <div className="col-4 col-sm-2 card-date text-white-alpha p-3">
                      <span className="card-dd display-4 text-300 d-block">
                        <Moment format="DD">{evento.node.fecha}</Moment>
                      </span>
                      <span className="card-mm text-100 d-block">
                        <Moment format="MMMM">{evento.node.fecha}</Moment>
                      </span>
                      <span className="card-yy text-100 d-block">
                        <Moment format="YYYY">{evento.node.fecha}</Moment>
                      </span>
                    </div>
                    <div className="col-8 col-sm-4 card-cover">
                      <img
                        src={
                          evento.node.imagen_principal
                            ? evento.node.imagen_principal.publicURL
                            : "http://placehold.it/300x180"
                        }
                        alt={evento.node.nombre}
                        className="card-img rounded-right w-100"
                      />
                    </div>
                    <div className="col-12 col-sm-6 position-static pl-0 pl-sm-4 mt-3 mt-sm-0">
                      <i className="fa fa-calendar text-blue-alpha position-absolute d-none d-sm-block"></i>{" "}
                      <p className="card-title text-500 ml-0 ml-sm-4">
                        {evento.node.nombre}
                      </p>
                      <div className="card-text ml-0 ml-sm-4 mt-n3">
                        <ContentToHTML content={evento.node.descripcion} />
                      </div>
                      <Link
                        to={`/events/${evento.node.slug}`}
                        className="stretched-link"
                      >
                        {""}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {startDate == null && endDate == null && (
          <Pager pageContext={pageContext} />
        )}
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query EventsQueryPagination($skip: Int!, $limit: Int!) {
    allStrapiMediaEvento(
      sort: { order: DESC, fields: fecha }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          strapiId
          slug
          nombre
          meta {
            og_titulo
            og_imagen {
              publicURL
            }
            og_descripcion
          }
          fecha
          imagen_principal {
            publicURL
          }
          descripcion

          contenido {
            id
            tipo_componente

            # Component Texto

            contenido

            # Component Documento

            titulo
            fecha_publicacion
            es_descargable
            descripcion
            archivo {
              publicURL
              extension
            }

            # Component Banner

            slides {
              tipo_componente
              titulo
              subtitulo
              imagen {
                publicURL
              }
              link {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
            }

            # Component Card

            imagen {
              publicURL
            }
            link {
              nombre
              tipo
              link_id
              icono {
                publicURL
              }
            }

            # Component Grupo Cards

            cards {
              tipo_componente
              titulo
              descripcion
              imagen {
                publicURL
              }
              link {
                nombre
                tipo
                link_id
                icono {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`
